import { Wrapper, Logo, HeaderWrapper, LogoWrapper } from './headerStyles';
import { TranslationToggle } from '../translation-toggle/TranslationToggle';
import theme from '../../skins/baseIndex.json';
function Header() {
  return (
    <Wrapper>
      <HeaderWrapper>
        <LogoWrapper>
          <Logo src={theme.headerLogo} alt={theme.headersLogoAlt} />
        </LogoWrapper>
        <TranslationToggle />
      </HeaderWrapper>
    </Wrapper>
  );
}

export default Header;
