import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import theme from '../skins/baseIndex.json';
interface StyledProps {
  padding: string | undefined | null;
  margin: string | undefined | null;
  backgroundColor: string | undefined | null;
  color: string | undefined;
  hoverBackColor: string | undefined;
  hoverTextColor: string | undefined;
  disabledBackColor: string | undefined;
  disabledTextColor: string | undefined;
  type?: 'submit' | 'reset' | 'button' | undefined;
}
interface ButtonInputProps {
  children?: ReactNode;
  padding?: string;
  margin?: string;
  backgroundColor?: string;
  color?: string;
  hoverBackColor?: string;
  hoverTextColor?: string;
  disabledBackColor?: string;
  disabledTextColor?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button' | undefined;
}
const StyledButton = styled.button<StyledProps>`
  padding: ${(props) => props.padding || '10px 15px'};
  margin: ${(props) => props.padding || 0};
  color: ${theme.buttonColor};
  background-color: ${theme?.buttonBackgroundColor};
  cursor: pointer;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  font-weight: 600;
  &:hover {
    background-color: ${(props) =>
      props.hoverBackColor || theme?.buttonBackgroundColorHover};
  }
  &:disabled {
    cursor: default;
    background-color: ${(props) =>
      props.disabledBackColor || theme?.buttonBackgroundColorDisabled};
    color: ${(props) => props.disabledTextColor || theme.buttonColorDisabled};
  }
`;

const CustomButton: FC<ButtonInputProps> = ({
  children,
  padding,
  margin,
  backgroundColor,
  color,
  hoverBackColor,
  hoverTextColor,
  disabledBackColor,
  disabledTextColor,
  onClick,
  disabled,
  type,
}) => {
  return (
    <StyledButton
      padding={padding}
      margin={margin}
      backgroundColor={backgroundColor}
      color={color}
      hoverBackColor={hoverBackColor}
      hoverTextColor={hoverTextColor}
      disabledBackColor={disabledBackColor}
      disabledTextColor={disabledTextColor}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {children}
    </StyledButton>
  );
};

export default CustomButton;
