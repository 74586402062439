import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations_en from './locales/en/translations.json';
import translations_fr from './locales/fr/translations.json';
import LngDetector from 'i18next-browser-languagedetector';
i18next
  .use(initReactI18next)
  .use(LngDetector)
  .init({
    fallbackLng: 'en',
    resources: {
      en: {
        translation: translations_en,
      },
      fr: {
        translation: translations_fr,
      },
    },
    returnObjects: true,
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      wait: true,
    },
  });

i18next.languages = ['en', 'fr'];

export default i18next;
