import React, { useState, useRef } from 'react';
import { Formik } from 'formik';
import { AuthCodeRef } from 'react-auth-code-input';
import { TextField, FormHelperText, Dialog } from '@mui/material';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ButtonWrapper } from './authStyles';
import CustomButton from '../../components/CustomButton';
import { useAppDispatch, useAppSelector } from '../../store/reduxHooks';
import { object, string } from 'yup';
import { forgotPassword, confirmVerificationCode } from './authActions';
import StickyErrorMessageBox from '../../components/StickyErrorMessageBox';
import PhoneInput from 'react-phone-input-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  ForgotPasswordWrapper,
  ForgotPasswordContentWrapper,
  Title,
  Message,
  Icon,
  BackText,
  BackToSelection,
  ActionWrapper,
  Action,
  ModalWrapper,
  Cancel,
  TitleWrapper,
} from './authStyles';
import { clearError } from './authSlice';
import { ForgotPaswordMethodProps } from './auth.types';
import { useTranslation } from 'react-i18next';
import appConfig from '../../skins/baseIndex.json';
// aws cloudfront create-invalidation --distribution-id E1QYXMKI8L5LME --paths '/*'
const ForgotPasswordVerficicationContainer: React.FC<
  ForgotPaswordMethodProps
> = ({
  enteredPhoneNumber,
  enteredEmail,
  step,
  setStep,
  setEnteredPhoneNumber,
  setEnteredEmail,
}) => {
  const { t } = useTranslation();
  const initialValue = { verification_code: '' };
  const dispatch = useAppDispatch();
  const responseError = useAppSelector(
    (state) => state.authReducer.responseError
  );
  const validationSchema = object().shape({
    verification_code: string().required(t('fieldValidation.required')),
  });
  const AuthInputRef = useRef<AuthCodeRef>(null);
  const [verifyButtonText, setVerifyButtonText] = useState<string>('');
  const [verfiyModalMessage, setVerfiyModalMessage] = useState<string>('');
  const [resendCodeModalIsVisible, setResendCodeModalIsVisible] =
    useState<boolean>(false);
  const resendCode = () => {
    const resendObject =
      step?.method === 'email'
        ? {
            email: enteredEmail,
            source: appConfig.configs.appSource,
          }
        : {
            phone_number: enteredPhoneNumber,
            source: appConfig.configs.appSource,
          };
    dispatch(
      forgotPassword({
        payload: resendObject,
        setStep,
        step,
      })
    );
    setResendCodeModalIsVisible(false);
    AuthInputRef.current?.clear();
  };
  const showVerifyModal = (type: string) => {
    let title;
    if (type === 'resend') {
      setResendCodeModalIsVisible(true);
    } else {
      setStep({ id: 1, method: step?.method === 'email' ? 'sms' : 'email' });
    }
    setVerifyButtonText('Resend Code');
    title = `${
      step?.method !== 'email'
        ? t('signinModal.verifyEmail')
        : t('signinModal.verifyPhone')
    }`;
    setVerfiyModalMessage(title);
  };
  const isEmailValid = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(
    enteredEmail
  );
  return (
    <ForgotPasswordWrapper>
      {responseError && !resendCodeModalIsVisible && (
        <StickyErrorMessageBox errors={responseError} borderRadius='15px' />
      )}
      <ForgotPasswordContentWrapper>
        <BackToSelection
          onClick={() => {
            setStep({ id: 1, method: enteredEmail ? 'email' : 'sms' });
            dispatch(clearError());
          }}
        >
          <Icon icon={faChevronLeft} fontSize='15px'></Icon>
          <BackText>{t('signinModal.back')}</BackText>
        </BackToSelection>
        <Title align='left'>{t('signinModal.verificationCode')}</Title>
        <Message>{t('signinModal.verificationMessage')}</Message>
        <Formik
          initialValues={initialValue}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            let payload: {
              verification_code: string;
              source: string;
              email?: string;
              phone_number?: string;
            } = {
              source: appConfig.configs.appSource,
              verification_code: values.verification_code,
            };
            if (enteredEmail) {
              payload = {
                ...payload,
                email: enteredEmail,
              };
            }
            if (enteredPhoneNumber) {
              payload = {
                ...payload,
                phone_number: enteredPhoneNumber,
              };
            }
            dispatch(confirmVerificationCode({ payload, setStep, step }));
          }}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                id='RegistrationForm-Email-Field'
                fullWidth
                label={t('signinModal.enterCode')}
                name='verification_code'
                type='text'
                size='small'
                autoComplete='username'
                value={values.verification_code}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.verification_code && errors.verification_code
                    ? true
                    : false
                }
                sx={{
                  backgroundColor: '#fff',
                }}
                autoFocus
              />
              {touched.verification_code && errors.verification_code && (
                <FormHelperText
                  style={{ color: 'red', marginLeft: '5px' }}
                  error={
                    touched.verification_code && errors.verification_code
                      ? true
                      : false
                  }
                >
                  {errors.verification_code}
                </FormHelperText>
              )}
              <ButtonWrapper margin='10px 0 0 0'>
                <CustomButton
                  type='submit'
                  disabled={Object.keys(errors).length > 0 || !dirty}
                  padding='10px 50px'
                >
                  {t('signinModal.continue')}
                </CustomButton>
              </ButtonWrapper>
              <ActionWrapper>
                <Action onClick={() => showVerifyModal('resend')}>
                  {t('signinModal.resendCode')}
                </Action>
                <Action onClick={() => showVerifyModal('change')}>
                  {t('signinModal.sendViaInstaed', {
                    verifyMethod: enteredEmail
                      ? t('signinModal.sms')
                      : t('signinModal.email'),
                  })}
                </Action>
              </ActionWrapper>
            </form>
          )}
        </Formik>
        {resendCodeModalIsVisible && (
          <Dialog
            open={resendCodeModalIsVisible}
            onBackdropClick={() => setResendCodeModalIsVisible(false)}
            fullWidth={window.innerWidth < 786}
            fullScreen={window.innerWidth < 786}
            scroll='body'
          >
            {responseError && (
              <StickyErrorMessageBox
                errors={responseError}
                borderRadius='4px'
                marginTop='0px'
              />
            )}
            <ModalWrapper>
              <TitleWrapper>
                <Title id='Registration-Title' align='left'>
                  {verfiyModalMessage}
                </Title>
                <Cancel
                  id='Registration-CancelButton'
                  onClick={() => {
                    setResendCodeModalIsVisible(false);
                    //   clearAuthErrorMessage();
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Cancel>
              </TitleWrapper>
              {step?.method === 'email' ? (
                <>
                  <TextField
                    id='RegistrationForm-Email-Field'
                    fullWidth
                    label={t('signinModal.email')}
                    name='email'
                    type='email'
                    size='small'
                    autoComplete='username'
                    value={enteredEmail}
                    onChange={(e) => {
                      setEnteredEmail(e.target.value);
                      setEnteredPhoneNumber('');
                    }}
                    // error={touched.email && errors.email ? true : false}
                    sx={{
                      backgroundColor: '#fff',
                    }}
                    autoFocus
                  />
                  {(!enteredEmail || !isEmailValid) && (
                    <FormHelperText style={{ color: 'red', marginLeft: '5px' }}>
                      {t('fieldValidation.validEmailFormat')}
                    </FormHelperText>
                  )}
                </>
              ) : (
                <>
                  <PhoneInput
                    specialLabel={t('signinModal.phone')}
                    placeholder='(123) 456-7890'
                    country={'ca'}
                    onlyCountries={['ca', 'us']}
                    masks={{ ca: '(...) ...-....', us: '(...) ...-....' }}
                    value={enteredPhoneNumber}
                    onChange={(phone) => {
                      setEnteredPhoneNumber(phone);
                      setEnteredEmail('');
                    }}
                    inputStyle={{ width: '100%', height: '40px' }}
                    inputProps={{
                      name: 'phone_number',
                    }}
                    disableCountryCode={true}
                  />
                  {(!enteredPhoneNumber ||
                    enteredPhoneNumber.length !== 10) && (
                    <FormHelperText style={{ color: 'red', marginLeft: '5px' }}>
                      {t('fieldValidation.validPhoneNumberFormat')}
                    </FormHelperText>
                  )}
                </>
              )}
              <div style={{ margin: '0 auto' }}>
                <CustomButton
                  disabled={
                    (step.method === 'email' && !enteredEmail) ||
                    (step.method !== 'email' && !enteredPhoneNumber)
                  }
                  type='button'
                  onClick={resendCode}
                >
                  {verifyButtonText}
                </CustomButton>
              </div>
            </ModalWrapper>
          </Dialog>
        )}
      </ForgotPasswordContentWrapper>
    </ForgotPasswordWrapper>
  );
};

export default ForgotPasswordVerficicationContainer;
