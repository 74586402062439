import React, { useEffect } from 'react';
import './App.css';
import Header from './modules/header/Header';
import { useAppDispatch } from './store/reduxHooks';
import { getConfig, getSession } from './modules/auth/authActions';
import Content from './modules/content';
import { createGlobalStyle } from 'styled-components';
import appConfig from './skins/baseIndex.json';
const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Avenir', sans-serif;
    src: url('./assets/fonts/Avenir.ttc') format('truetype-collection');
    font-weight: normal;
    font-style: normal;
  }
  body {
    font-family: 'Avenir', sans-serif;
  }
`;
function App() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getConfig(null));
    dispatch(getSession(null));
    const changeFavicon = (url: string) => {
      let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
      if (!link) {
        link = document.createElement('link') as HTMLLinkElement;
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = url;
    };
    changeFavicon(`icons/${appConfig.configs.faviconFilename}.ico`);
    document.title = appConfig.contents.clientName;
  }, [dispatch]);
  return (
    <>
      <GlobalStyle />
      <Header />
      <section className='login-wrapper'>
        <Content />
      </section>
    </>
  );
}

export default App;
