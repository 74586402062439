import axios from 'axios';
import i18next from 'i18next';
const ROOT_URL = process.env.REACT_APP_ROOT_URL;

export const api = axios.create({
  baseURL: ROOT_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'accept-language': 'en-CA',
  },
});

api.interceptors.request.use((request) => {
  if (i18next.language === 'fr') {
    request.headers['accept-language'] = 'fr-CA';
  }
  if (
    !request.url?.includes('/session') &&
    !request.url?.includes('/login') &&
    !request.url?.includes('/config') &&
    request.url !== '/registration'
  ) {
    request.headers['X-SP-API-Token'] = localStorage.getItem('token');
  }
  return request;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 403) {
    } else if (error.response.status === 500) {
    }
    return Promise.reject(error);
  }
);

export default api;
