import React, { useEffect } from 'react';
import { useState } from 'react';
import { TextField, InputAdornment, FormHelperText } from '@mui/material';
import CustomButton from '../../components/CustomButton';
import { useAppDispatch, useAppSelector } from '../../store/reduxHooks';
import { login } from './authActions';
import { Login } from './auth.types';
import { clearError } from './authSlice';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import appConfig from '../../skins/baseIndex.json';
import {
  Wrapper,
  LoginImgWrapper,
  LoginText,
  ButtonRowWrapper,
  FieldWrapper,
  Title,
  LogoImg,
  ForgotPasswordLink,
  CustomIcon,
} from './authStyles';
import StickyErrorMessageBox from '../../components/StickyErrorMessageBox';
import { useTranslation } from 'react-i18next';
import theme from '../../skins/baseIndex.json';
const initialLogin = {
  email: '',
  password: '',
  source: appConfig.configs.appSource,
  latitude: 0,
  longitude: 0,
};
const LoginContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const responseError = useAppSelector(
    (state) => state.authReducer.responseError
  );
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loginObj, setLoginObj] = useState<Login>(initialLogin);
  const [error, setError] = useState<string>('');

  const isEmailValid = (email: string) => {
    const isValidEmail = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
      ? true
      : false;
    return isValidEmail;
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let newLogin = { ...loginObj };
    if (name === 'email') {
      const isValid = isEmailValid(value);
      if (isValid) {
        setError('');
      } else if (!newLogin.email) {
        setError(t('fieldValidation.required'));
      } else {
        setError(t('fieldValidation.validEmailFormat'));
      }
    }
    newLogin = { ...newLogin, [name]: value };
    setLoginObj(newLogin);
  };
  const handleLogin = () => {
    const updatedLogin = { ...loginObj };
    dispatch(login(updatedLogin));
  };
  useEffect(() => {
    if (!loginObj.email) {
      setError(t('fieldValidation.required'));
    }
    return () => {
      dispatch(clearError());
    };
  }, [dispatch, loginObj?.email, t]);
  return (
    <Wrapper>
      <LoginImgWrapper>
        <LogoImg src={theme.loginLogo} />
      </LoginImgWrapper>
      {responseError && <StickyErrorMessageBox errors={responseError} />}
      <LoginText>
        <Title>{t('title', { clientName: theme.contents.clientName })}</Title>
        <FieldWrapper>
          <TextField
            type='text'
            fullWidth
            size='small'
            name='email'
            label={t('signinModal.email')}
            placeholder='Email'
            value={loginObj.email}
            onChange={handleChange}
            error={!loginObj.email ? true : false}
            sx={{
              backgroundColor: '#fff',
            }}
            required
          />
          {error && (
            <FormHelperText style={{ color: 'red', marginLeft: '5px' }}>
              {error}
            </FormHelperText>
          )}
        </FieldWrapper>
        <FieldWrapper height='60px'>
          <TextField
            type={showPassword ? 'text' : 'password'}
            fullWidth
            required
            size='small'
            name='password'
            label={t('signinModal.password')}
            placeholder='Password'
            value={loginObj.password}
            onChange={handleChange}
            sx={{
              backgroundColor: '#fff',
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <CustomIcon
                    icon={showPassword ? faEye : faEyeSlash}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </InputAdornment>
              ),
            }}
          />
        </FieldWrapper>
        <ForgotPasswordLink to='/forgot-password' replace={true}>
          {t('signinModal.forgotPassword')}?
        </ForgotPasswordLink>
        <ButtonRowWrapper>
          <CustomButton
            onClick={handleLogin}
            disabled={
              !loginObj.email || !loginObj.password || error ? true : false
            }
            padding='10px 50px'
          >
            {t('signinModal.login')}
          </CustomButton>
        </ButtonRowWrapper>
      </LoginText>
    </Wrapper>
  );
};

export default LoginContainer;
