import styled from 'styled-components';
import theme from '../../skins/baseIndex.json';
export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  vertical-align: center;
  background-color: ${theme.headerBackgroundColor};
  margin-bottom: 15px;
`;
export const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  align-items: center;
  height: 80px;
  width: 1200px;
`;
export const LogoWrapper = styled.div`
  width: 200px;
  max-height: 75px;
`;
export const Logo = styled.img`
  max-height: 65px;
`;
export const SignoutWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0px;
`;
export const SignoutButton = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  font-size: 20px;
  font-weight: 600;
  color: #e4cd84;
  letter-spacing: 0.5px;
  &:hover {
    color: #ffce2e;
  }
  cursor: pointer;
`;
export const HeaderWrappers = styled.div`
  background-color: ${theme.headerBackgroundColor};
  width: 100%;
  height: 80px;
`;
