import React, { useState } from 'react';
import styled from 'styled-components';
interface StylignProps {
  width?: string;
  marginTop?: string;
  messageWidth?: string;
  borderRadius?: string;
}
interface StickyErrorMessageBoxProps extends StylignProps {
  errors: string | null;
}
const ErrorBox = styled.div<StylignProps>`
  width: ${(props) => props.width || '100%'};
  padding: 12px 0;
  background-color: #f2c8b8;
  position: -webkit-sticky;
  position: sticky;
  top: ${(props) => props.marginTop || '80px'};
  z-index: 2;
  max-height: 245px;
  border-top-right-radius: ${(props) => props.borderRadius || 0};
  border-top-left-radius: ${(props) => props.borderRadius || 0};
  @media screen and (max-width: 790px) {
    top: 70px;
    margin: 0;
  }
`;
// error message
const ErrorMessageWrapper = styled.div<StylignProps>`
  margin: 0 auto;
  /* width: ${(props) => props.messageWidth || '750px'}; */
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;
const ErrorTitle = styled.div`
  margin-left: 7px;
  font-size: 15px;
  font-weight: 600;
  overflow-wrap: break-word;
`;

const StickyErrorMessageBox: React.FC<StickyErrorMessageBoxProps> = ({
  errors,
  width,
  marginTop,
  messageWidth,
  borderRadius,
}) => {
  const errorMessage = errors;
  return (
    <ErrorBox width={width} marginTop={marginTop} borderRadius={borderRadius}>
      <ErrorMessageWrapper messageWidth={messageWidth}>
        <ErrorTitle>{errorMessage}</ErrorTitle>
      </ErrorMessageWrapper>
      {/* {errors?.details?.cart_changes && (
        <ErrorDetailWrapper messageWidth={messageWidth}>
          <ErrorList>
            {errors?.details?.cart_changes.map(
              (errorItem, index) =>
                (index < 3 || (index > 2 && displayMore)) && (
                  <ErrorListItem>{errorItem}</ErrorListItem>
                )
            )}
          </ErrorList>
        </ErrorDetailWrapper>
      )} */}
      {/* {errors?.details &&
        typeof errors?.details === 'object' &&
        errors?.details.hasOwnProperty('promo_codes][0') && (
          <ErrorDetailWrapper messageWidth={messageWidth}>
            <ErrorListItem>{errors?.details['promo_codes][0']}</ErrorListItem>
          </ErrorDetailWrapper>
        )} */}
      {/* {errors?.details?.cart_changes?.length > 3 && (
        <DisplayMoreWrapper messageWidth={messageWidth}>
          <DisplayMore
            onClick={() => setDisplayMore((prevState) => !prevState)}
          >
            {displayMore ? 'Less' : 'More'}
          </DisplayMore>
        </DisplayMoreWrapper>
      )} */}
    </ErrorBox>
  );
};

export default StickyErrorMessageBox;
