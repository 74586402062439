import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import appConfig from '../../skins/baseIndex.json';
import {
  login,
  register,
  getConfig,
  getSession,
  confirmSMS,
  resendRegistrationCode,
  forgotPassword,
  confirmVerificationCode,
  resetPassword,
} from './authActions';

import { AuthState, DisplayAlert, SuccssfulLogin } from './auth.types';
const initialState: AuthState = {
  user: null,
  allUsers: [],
  isGettingLoggedIn: false,
  token: null,
  responseError: null,
  isAlertDispalyed: false,
  alertType: 'success',
  alertMessage: '',
  minPasswordLength: 0,
  config: {},
};
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('token');
      state.token = null;
    },
    closeAlert: (state) => {
      state.isAlertDispalyed = false;
    },
    displayAlert: (state, action: PayloadAction<DisplayAlert>) => {
      state.alertType = action.payload.alertType;
      state.alertMessage = action.payload.alertMessage;
      state.isAlertDispalyed = action.payload.isAlertDispalyed;
    },
    clearError: (state) => {
      state.responseError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isGettingLoggedIn = true;
    });
    builder.addCase(
      login.fulfilled,
      (state, action: PayloadAction<SuccssfulLogin>) => {
        state.isGettingLoggedIn = false;
        state.token = action.payload.data.access_token;
        localStorage.setItem('token', action.payload?.data?.access_token);
        //
        state.responseError = '';
        window.location.replace(appConfig.configs.redirectLink);
      }
    );
    builder.addCase(login.rejected, (state, action: PayloadAction<any>) => {
      state.isGettingLoggedIn = false;
      if (action?.payload.message) {
        state.responseError = action?.payload.message;
      } else {
        state.responseError = action?.payload.errors.message;
      }
    });
    // getConfig
    builder.addCase(
      getConfig.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.config = action.payload.data.data;
        state.minPasswordLength =
          action.payload.data.data.password_complexity_min_length;
      }
    );
    // get session
    builder.addCase(
      getSession.fulfilled,
      (state, action: PayloadAction<any>) => {
        localStorage.setItem('token', action.payload?.data?.data?.session_id);
      }
    );
    // register
    builder.addCase(register.fulfilled, (state, action: PayloadAction<any>) => {
      localStorage.setItem('token', action.payload?.data?.data.token);
      action.payload.navigate('/verify');
      state.responseError = '';
    });
    builder.addCase(register.rejected, (state, action: PayloadAction<any>) => {
      state.isGettingLoggedIn = false;
      if (action?.payload.message) {
        state.responseError = action?.payload.message;
      } else {
        state.responseError = action?.payload.errors.message;
      }
    });
    // resend code
    builder.addCase(
      resendRegistrationCode.fulfilled,
      (state, action: PayloadAction<any>) => {
        localStorage.setItem('token', action.payload?.data?.data.token);
        action.payload?.setResendCodeModalIsVisible(false);
      }
    );
    builder.addCase(
      resendRegistrationCode.rejected,
      (state, action: PayloadAction<any>) => {
        state.isGettingLoggedIn = false;
        if (action?.payload.message) {
          state.responseError = action?.payload.message;
        } else {
          state.responseError = action?.payload.errors.message;
        }
      }
    );
    // confirm code
    builder.addCase(
      confirmSMS.fulfilled,
      (state, action: PayloadAction<any>) => {
        window.location.replace(appConfig.configs.redirectLink);
        state.responseError = '';
      }
    );
    builder.addCase(
      confirmSMS.rejected,
      (state, action: PayloadAction<any>) => {
        state.isGettingLoggedIn = false;
        if (action?.payload.message) {
          state.responseError = action?.payload.message;
        } else {
          state.responseError = action?.payload.errors.message;
        }
      }
    );
    // confirm password verification code
    builder.addCase(
      confirmVerificationCode.fulfilled,
      (state, action: PayloadAction<any>) => {
        localStorage.setItem(
          'password_reset_token',
          action.payload?.data?.data.token
        );
        action.payload.setStep({ id: 3, method: action.payload.step.method });
        state.responseError = '';
      }
    );
    builder.addCase(
      confirmVerificationCode.rejected,
      (state, action: PayloadAction<any>) => {
        state.isGettingLoggedIn = false;
        if (action?.payload.message) {
          state.responseError = action?.payload.message;
        } else {
          state.responseError = action?.payload.errors.message;
        }
      }
    );
    // forgot password
    builder.addCase(
      forgotPassword.fulfilled,
      (state, action: PayloadAction<any>) => {
        action.payload.setStep({ id: 2, method: action.payload.step.method });
        state.responseError = '';
      }
    );
    builder.addCase(
      forgotPassword.rejected,
      (state, action: PayloadAction<any>) => {
        state.isGettingLoggedIn = false;
        if (action?.payload.message) {
          state.responseError = action?.payload.message;
        } else {
          state.responseError = action?.payload.errors.message;
        }
      }
    );
    // reset password
    builder.addCase(
      resetPassword.fulfilled,
      (state, action: PayloadAction<any>) => {
        localStorage.removeItem('password_reset_token');
        window.location.replace(appConfig.configs.redirectLink);
        state.responseError = '';
      }
    );
    builder.addCase(
      resetPassword.rejected,
      (state, action: PayloadAction<any>) => {
        state.isGettingLoggedIn = false;
        if (action?.payload.message) {
          state.responseError = action?.payload.message;
        } else {
          state.responseError = action?.payload.errors.message;
        }
      }
    );
  },
});
export const { logout, closeAlert, displayAlert, clearError } =
  authSlice.actions;
const authReducer = authSlice.reducer;
export default authReducer;
