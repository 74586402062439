import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import appConfig from '../../skins/baseIndex.json';
interface StyleProps {
  height?: string;
  align?: string;
  margin?: string;
  fontSize?: string;
  active?: boolean;
}
export const Wrapper = styled.div`
  max-width: 380px;
  width: 100%;
  border-radius: 15px;
  background-color: #f0f0f0;
`;
export const CustomIcon = styled(FontAwesomeIcon)<StyleProps>`
  font-size: 16px;
  color: ${(props) =>
    props.active ? appConfig.buttonBackgroundColor : '#66696e'};
  cursor: pointer;
`;
export const LoginImgWrapper = styled.div`
  min-height: 200px;
  background-color: ${appConfig.loginLogoBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  border-start-start-radius: 15px;
  border-start-end-radius: 15px;
`;
export const LogoImg = styled.img`
  height: 150px;
`;
export const LoginText = styled.div`
  padding: 20px 15px;
`;
export const ButtonRowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const FieldWrapper = styled.div<StyleProps>`
  height: ${(props) => props.height || '70px'};
`;

export const Title = styled.h2<StyleProps>`
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: ${(props) => props.align || 'center'};
  width: 100%;
`;

export const Label = styled.div`
  width: 100%;
  padding: 0px 5px 5px 0px;
  margin: 0;
`;

export const SwitchWrapper = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 42px;
  width: 100%;
  align-items: center;
`;

export const FormLayout = styled.div`
  grid-gap: 22px;
`;

export const TermsConditions = styled.h1`
  font-size: 16px;
  width: 100%;
  line-height: 25px;
`;
export const ButtonWrapper = styled.div<StyleProps>`
  display: flex;
  justify-content: center;
  font-size: 16px;
  margin: ${(props) => props.margin || 0};
`;
export const TermsLink = styled.a`
  text-decoration: none;
  color: red;
`;
export const ContentContainer = styled.div``;

/// verification
export const VerificationAndChangeNumberWrapper = styled.div``;

export const ErrorMessageWrapper = styled.div`
  padding: 0;
  margin: 0;
  width: 440px;
  @media (max-width: 786px) {
    width: 100%;
  }
`;
export const ModalWrapper = styled.div`
  width: 400px;
  padding: 20px;
  background: white;
  margin: auto;
  display: grid;
  grid-gap: 10px;
  @media (max-width: 786px) {
    padding: 24px 20px;
    width: 100%;
  }
`;

export const TitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 10px;
`;

export const Cancel = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: white;
  background-color: rgba(81, 78, 89, 0.63);

  right: 8px;
  top: 8px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.closeButtonHoverColor};
    transition: all 0.25s ease;
  }
`;

export const MessageWrapper = styled.p`
  font-size: 16px;
  color: #000000;
  margin-bottom: 40px;
  padding: 0 25px;
  margin-bottom: 20px;
`;
export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
`;
export const Action = styled.div`
  font-size: 19px;
  font-weight: 600;
  color: #002760;
  margin-bottom: 24px;
  cursor: pointer;
  max-width: 370px;
  text-align: center;
`;
export const MessageContainer = styled.div`
  height: 100px;
  margin-bottom: 15px;
`;
export const CodeWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  .verification-input {
    height: 40px;
    width: 40px;
    line-height: 35px;
    font-size: 18px;
    margin: 0 5px;
    text-align: center;
    border-radius: 5px;
  }
`;
// Forgot Password
export const ForgotPasswordWrapper = styled.div``;
export const ForgotPasswordContentWrapper = styled.div`
  padding: 15px;
`;
export const Message = styled.p`
  font-size: 17px;
  line-height: 25px;
`;
export const ForgotPasswordLink = styled(Link)`
  margin-left: 10px;
  margin-bottom: 10px;
  text-decoration: none;
  color: ${appConfig.buttonBackgroundColor};
`;
export const BackToSelection = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #6ba543;
  font-weight: 600;
  cursor: pointer;
`;
export const MethodWrapper = styled.div<StyleProps>`
  display: grid;
  grid-template-columns: 1fr 50px;
  padding: 12px;
  align-items: center;
  border: 1px solid #807c7c;
  border-radius: 7px;
  margin: ${(props) => props.margin || 0};
  cursor: pointer;
`;
export const MethodTitle = styled.div`
  font-size: 17px;
  line-height: 25px;
  font-weight: 600;
`;
export const Icon = styled(FontAwesomeIcon)<StyleProps>`
  color: ${appConfig.buttonBackgroundColor};
  font-size: ${(props) => props.fontSize || '30px'};
`;
export const BackText = styled.span`
  color: ${appConfig.buttonBackgroundColor};
  margin-left: 7px;
`;
