import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import api from '../../api';
import {
  Login,
  Session,
  SuccssfulLogin,
  RegisterPayload,
  ResendCodePayload,
  ConfirmCodePayload,
  ConfirmVerificationCode,
  ResetPassword,
  ConfigAsyncThunk,
  ForgotPaswordPayload,
  SessionAsyncThunk,
  LoginAsyncThunk,
  RegisterAsyncThunk,
  ResendCodeAsyncThunk,
  ConfirmCodeAsyncThunk,
  ForgotPasswordThunk,
  ConfirmVerificationCodeAsyncThunk,
  ResetPasswordAsyncThunk,
} from './auth.types';
import appConfig from '../../skins/baseIndex.json';
import i18next from 'i18next';
const root_url = process.env.REACT_APP_ROOT_URL;

export const getConfig: ConfigAsyncThunk = createAsyncThunk(
  'auth/config',
  async (_, thunkApi) => {
    try {
      const res = await api.get(
        `/config?source=${appConfig.configs.appSource}&platform_id=3`
      );
      const { data } = await res;
      return { data } as Session;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        return thunkApi.rejectWithValue({
          message: 'Something went wrong: Network Error (Cors Origin)',
        });
      } else if (error?.response?.data) {
        return thunkApi.rejectWithValue(error?.response?.data);
      } else {
        return thunkApi.rejectWithValue({
          message: 'Something went wrong: Unkown Reason ',
        });
      }
    }
  }
);
export const getSession: SessionAsyncThunk = createAsyncThunk(
  'auth/session',
  async (_, thunkApi) => {
    try {
      const res = await api.post(`/sessions`, {
        source: appConfig.configs.appSource,
      });
      return res as any;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        return thunkApi.rejectWithValue({
          message: 'Something went wrong: Network Error (Cors Origin)',
        });
      } else if (error?.response?.data) {
        return thunkApi.rejectWithValue(error?.response?.data);
      } else {
        return thunkApi.rejectWithValue({
          message: 'Something went wrong: Unkown Reason ',
        });
      }
    }
  }
);
export const login: LoginAsyncThunk = createAsyncThunk(
  'auth/login',
  async (loginData: Login, thunkApi) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'accept-language': i18next.language === 'fr' ? 'fr-CA' : 'en-CA',
      };
      const res = await axios.post(
        `${root_url}/login`,
        { ...loginData },
        { headers }
      );
      return res as SuccssfulLogin;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        return thunkApi.rejectWithValue({
          message: 'Something went wrong: Network Error (Cors Origin)',
        });
      } else if (error?.response?.data) {
        return thunkApi.rejectWithValue(error?.response?.data);
      } else {
        return thunkApi.rejectWithValue({
          message: 'Something went wrong: Unkown Reason ',
        });
      }
    }
  }
);
export const register: RegisterAsyncThunk = createAsyncThunk(
  'auth/register',
  async ({ payload, navigate }: RegisterPayload, thunkApi) => {
    try {
      const res = await api.post(`/registration`, {
        ...payload,
        source: appConfig.configs.appSource,
      });
      const { data } = await res;
      return { data, navigate };
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        return thunkApi.rejectWithValue({
          message: 'Something went wrong: Network Error (Cors Origin)',
        });
      } else if (error?.response?.data) {
        return thunkApi.rejectWithValue(error?.response?.data);
      } else {
        return thunkApi.rejectWithValue({
          message: 'Something went wrong: Unkown Reason ',
        });
      }
    }
  }
);
export const resendRegistrationCode: ResendCodeAsyncThunk = createAsyncThunk(
  'auth/resendCode',
  async (
    { payload, setResendCodeModalIsVisible }: ResendCodePayload,
    thunkApi
  ) => {
    try {
      const res = await api.patch(`/registration/phone`, payload);
      const { data } = await res;
      return { data, setResendCodeModalIsVisible };
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        return thunkApi.rejectWithValue({
          message: 'Something went wrong: Network Error (Cors Origin)',
        });
      } else if (error?.response?.data) {
        return thunkApi.rejectWithValue(error?.response?.data);
      } else {
        return thunkApi.rejectWithValue({
          message: 'Something went wrong: Unkown Reason ',
        });
      }
    }
  }
);
export const confirmSMS: ConfirmCodeAsyncThunk = createAsyncThunk(
  'auth/confirmCode',
  async ({ payload, navigate }: ConfirmCodePayload, thunkApi) => {
    try {
      const res = await api.post(`/registration/phone/confirmation`, payload);
      const { data } = await res;
      return { data, navigate };
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        return thunkApi.rejectWithValue({
          message: 'Something went wrong: Network Error (Cors Origin)',
        });
      } else if (error?.response?.data) {
        return thunkApi.rejectWithValue(error?.response?.data);
      } else {
        return thunkApi.rejectWithValue({
          message: 'Something went wrong: Unkown Reason ',
        });
      }
    }
  }
);
export const confirmVerificationCode: ConfirmVerificationCodeAsyncThunk =
  createAsyncThunk(
    'auth/confirmVerificationCode',
    async ({ payload, setStep, step }: ConfirmVerificationCode, thunkApi) => {
      try {
        const res = await api.post(
          `/password/confirm-verification-code`,
          payload
        );
        const { data } = await res;
        return { data, setStep, step };
      } catch (error: any) {
        if (typeof error.response === 'undefined') {
          return thunkApi.rejectWithValue({
            message: 'Something went wrong: Network Error (Cors Origin)',
          });
        } else if (error?.response?.data) {
          return thunkApi.rejectWithValue(error?.response?.data);
        } else {
          return thunkApi.rejectWithValue({
            message: 'Something went wrong: Unkown Reason ',
          });
        }
      }
    }
  );
export const forgotPassword: ForgotPasswordThunk = createAsyncThunk(
  'auth/forgotPassword',
  async ({ payload, setStep, step }: ForgotPaswordPayload, thunkApi) => {
    try {
      const res = await api.post(`password/forgot-password`, payload);
      const { data } = await res;
      return { data, setStep, step };
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        return thunkApi.rejectWithValue({
          message: 'Something went wrong: Network Error (Cors Origin)',
        });
      } else if (error?.response?.data) {
        return thunkApi.rejectWithValue(error?.response?.data);
      } else {
        return thunkApi.rejectWithValue({
          message: 'Something went wrong: Unkown Reason ',
        });
      }
    }
  }
);
export const resetPassword: ResetPasswordAsyncThunk = createAsyncThunk(
  'auth/restPassword',
  async (payload: ResetPassword, thunkApi) => {
    try {
      const passwordResetToken = localStorage.getItem('password_reset_token');
      const res = await api.post(`/password/reset`, {
        ...payload,
        token: passwordResetToken,
      });
      const { data } = await res;
      return data;
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        return thunkApi.rejectWithValue({
          message: 'Something went wrong: Network Error (Cors Origin)',
        });
      } else if (error?.response?.data) {
        return thunkApi.rejectWithValue(error?.response?.data);
      } else {
        return thunkApi.rejectWithValue({
          message: 'Something went wrong: Unkown Reason ',
        });
      }
    }
  }
);
