import * as React from 'react';
import { ForgotPaswordMethodSelectionProps } from './auth.types';
import { faEnvelope, faCommentAlt } from '@fortawesome/free-regular-svg-icons';
import {
  ForgotPasswordContentWrapper,
  Title,
  Message,
  MethodWrapper,
  Icon,
  MethodTitle,
  BackToSelection,
  BackText,
} from './authStyles';
import { useTranslation } from 'react-i18next';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
const ForgotPasswordMethodSelection: React.FC<
  ForgotPaswordMethodSelectionProps
> = ({ setStep }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <ForgotPasswordContentWrapper>
      <BackToSelection
        onClick={() => {
          navigate('/login');
        }}
      >
        <Icon icon={faChevronLeft} fontSize='15px'></Icon>
        <BackText>{t('signinModal.back')}</BackText>
      </BackToSelection>
      <Title align='left'>{t('signinModal.forgotPassword')}</Title>
      <Message>{t('signinModal.forgotPasswordMainMessage')}</Message>
      <MethodWrapper onClick={() => setStep({ id: 1, method: 'email' })}>
        <MethodTitle>{t('signinModal.viaEmail')}</MethodTitle>
        <Icon icon={faEnvelope}></Icon>
      </MethodWrapper>
      <MethodWrapper
        margin='10px 0 0'
        onClick={() => setStep({ id: 1, method: 'sms' })}
      >
        <MethodTitle>{t('signinModal.viaSMS')}</MethodTitle>
        <Icon icon={faCommentAlt}></Icon>
      </MethodWrapper>
    </ForgotPasswordContentWrapper>
  );
};

export default ForgotPasswordMethodSelection;
