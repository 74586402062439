import React, { useState } from 'react';
import { Wrapper } from './authStyles';
import ForgotPasswordMethodSelection from './ForgotPasswordMethodSelection';
import ForgotPasswordMethod from './ForgotPasswordMethod';
import ForgotPasswordVerficicationContainer from './ForgotPasswordVerficicationContainer';
import { ForgotPaswordContainerProps } from './auth.types';
import PasswordReset from './PasswordRest';

const ForgotPasswordContainer: React.FC<ForgotPaswordContainerProps> = ({
  enteredPhoneNumber,
  setEnteredPhoneNumber,
  enteredEmail,
  setEnteredEmail,
}) => {
  const [step, setStep] = useState<{ id: number; method: string }>({
    id: 0,
    method: 'email',
  });

  return (
    <Wrapper>
      {step.id === 0 ? (
        <ForgotPasswordMethodSelection setStep={setStep} />
      ) : step.id === 1 ? (
        <ForgotPasswordMethod
          step={step}
          setStep={setStep}
          enteredPhoneNumber={enteredPhoneNumber}
          setEnteredPhoneNumber={setEnteredPhoneNumber}
          enteredEmail={enteredEmail}
          setEnteredEmail={setEnteredEmail}
        />
      ) : step.id === 2 ? (
        <ForgotPasswordVerficicationContainer
          step={step}
          setStep={setStep}
          enteredPhoneNumber={enteredPhoneNumber}
          setEnteredPhoneNumber={setEnteredPhoneNumber}
          enteredEmail={enteredEmail}
          setEnteredEmail={setEnteredEmail}
        />
      ) : (
        <PasswordReset />
      )}
    </Wrapper>
  );
};

export default ForgotPasswordContainer;
