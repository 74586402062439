import React, { useState } from 'react';
import { Formik } from 'formik';
import { styled } from '@mui/material/styles';
import { TextField, FormHelperText } from '@mui/material';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Switch, { SwitchProps } from '@mui/material/Switch';
import CustomButton from '../../components/CustomButton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PasswordField from '../../components/PasswordField';
import { object, string } from 'yup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useAppDispatch, useAppSelector } from '../../store/reduxHooks';
import { Register, RegistrationProps } from './auth.types';
import {
  FieldWrapper,
  ButtonWrapper,
  TermsConditions,
  SwitchWrapper,
  TermsLink,
  Title,
} from './authStyles';
import { useNavigate } from 'react-router-dom';
import { register } from './authActions';
import { useTranslation } from 'react-i18next';
import skin from '../../skins/baseIndex.json';
const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: skin.buttonBackgroundColor,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: skin.buttonBackgroundColor,
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const initialValues: Register = {
  first_name: '',
  last_name: '',
  password: '',
  phone_number: '',
  marketing: true,
  birthdate: '',
  email: '',
  referral_code: '',
};

const RegistrationForm: React.FC<RegistrationProps> = ({
  enteredPhoneNumber,
  setEnteredPhoneNumber,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const minPasswordLength = useAppSelector(
    (state) => state.authReducer.minPasswordLength
  );
  const appConfig = useAppSelector((state) => state.authReducer.config);
  const [minLengthCheck, setMinLengthCheck] = useState(false);
  const [oneNumberCheck, setOneNumberCheck] = useState(false);
  const [oneSymbolCheck, setOneSymbolCheck] = useState(false);
  const [oneUpperCheck, setOneUpperCheck] = useState(false);
  const [oneLowerCheck, setOneLowerCheck] = useState(false);
  const validationSchema = object().shape({
    email: string()
      .matches(
        new RegExp(
          '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@' +
            '[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'
        ),
        t('fieldValidation.validEmailFormat')
      )
      .required(t('fieldValidation.required')),
    password: string()
      .required(t('fieldValidation.required'))
      .test('isValidPassword', 'Password Requirements Not Met', (value) => {
        let isValid = [false, false, false, false, false];
        const checkOneNumber = new RegExp('[0-9]').test(value);
        const checkOneLower = new RegExp('(?=.*[a-z])').test(value);
        const checkOneUpper = new RegExp('(?=.*[A-Z])').test(value);
        let specialChars = /[`!@#$%^&*()_\-+=\[\]{};':"\\|,.<>\/?~ ]/;
        const checkOneSymbol = specialChars.test(value);
        let len = value.length;
        if (len >= minPasswordLength) {
          setMinLengthCheck(true);
          isValid[0] = true;
        } else {
          setMinLengthCheck(false);
          isValid[0] = false;
        }
        if (checkOneNumber) {
          setOneNumberCheck(true);
          isValid[1] = true;
        } else {
          setOneNumberCheck(false);
          isValid[1] = false;
        }
        if (checkOneLower) {
          setOneLowerCheck(true);
          isValid[2] = true;
        } else {
          setOneLowerCheck(false);
          isValid[2] = false;
        }
        if (checkOneUpper) {
          setOneUpperCheck(true);
          isValid[3] = true;
        } else {
          setOneUpperCheck(false);
          isValid[3] = false;
        }
        if (checkOneSymbol) {
          setOneSymbolCheck(true);
          isValid[4] = true;
        } else {
          setOneSymbolCheck(false);
          isValid[4] = false;
        }
        return isValid.every((con) => con === true);
      }),

    first_name: string().required(t('fieldValidation.required')),
    last_name: string().required(t('fieldValidation.required')),
    phone_number: string()
      .required(t('fieldValidation.required'))
      .min(
        minPasswordLength,
        t('fieldValidation.required', { minPasswordLength: minPasswordLength })
      ),
  });
  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch(register({ payload: values, navigate }));
        }}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Title>{t('signinModal.signUp')}</Title>
              <FieldWrapper>
                <TextField
                  id='RegistrationForm-Email-Field'
                  fullWidth
                  label={t('signinModal.email')}
                  name='email'
                  type='email'
                  size='small'
                  autoComplete='username'
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && errors.email ? true : false}
                  sx={{
                    backgroundColor: '#fff',
                  }}
                  autoFocus
                />
                {touched.email && errors.email && (
                  <FormHelperText
                    style={{ color: 'red', marginLeft: '5px' }}
                    error={touched.email && errors.email ? true : false}
                  >
                    {errors.email}
                  </FormHelperText>
                )}
              </FieldWrapper>

              <PasswordField
                oneLowerCheck={oneLowerCheck}
                oneUpperCheck={oneUpperCheck}
                oneSymbolCheck={oneSymbolCheck}
                minLengthCheck={minLengthCheck}
                minPasswordLength={minPasswordLength}
                oneNumberCheck={oneNumberCheck}
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
              />

              <FieldWrapper>
                <TextField
                  id='RegistrationForm-FirstNameField'
                  fullWidth
                  label={t('signinModal.firstName')}
                  name='first_name'
                  type='text'
                  size='small'
                  autoComplete='given-name'
                  value={values.first_name}
                  onChange={handleChange}
                  error={touched.first_name && errors.first_name ? true : false}
                  onBlur={handleBlur}
                  sx={{
                    backgroundColor: '#fff',
                  }}
                />
                {touched.first_name && errors.first_name && (
                  <FormHelperText
                    style={{ color: 'red', marginLeft: '5px' }}
                    error={
                      touched.first_name && errors.first_name ? true : false
                    }
                  >
                    {errors.first_name}
                  </FormHelperText>
                )}
              </FieldWrapper>
              <FieldWrapper>
                <TextField
                  id='RegistrationForm-LastNameField'
                  fullWidth
                  label={t('signinModal.lastName')}
                  name='last_name'
                  type='text'
                  size='small'
                  autoComplete='family-name'
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.last_name && errors.last_name ? true : false}
                  sx={{
                    backgroundColor: '#fff',
                  }}
                />
                {touched.last_name && errors.last_name && (
                  <FormHelperText
                    style={{ color: 'red', marginLeft: '5px' }}
                    error={touched.last_name && errors.last_name ? true : false}
                  >
                    {errors.last_name}
                  </FormHelperText>
                )}
              </FieldWrapper>
              <FieldWrapper>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={t('signinModal.birthdayOptional')}
                    value={values.birthdate ? dayjs(values.birthdate) : null}
                    disableFuture
                    onChange={(date) =>
                      setFieldValue(
                        'birthdate',
                        dayjs(date).format('YYYY-MM-DD')
                      )
                    }
                    slotProps={{
                      textField: {
                        size: 'small',
                        fullWidth: true,
                        sx: {
                          backgroundColor: '#fff',
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              </FieldWrapper>
              {appConfig.show_referral_code && (
                <FieldWrapper>
                  <TextField
                    id='RegistrationForm-ReferralCode'
                    fullWidth
                    label={t('signinModal.referralCodeOptional')}
                    name='referral_code'
                    type='text'
                    size='small'
                    value={values.referral_code}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{
                      backgroundColor: '#fff',
                    }}
                  />
                </FieldWrapper>
              )}

              <FieldWrapper>
                <PhoneInput
                  specialLabel={t('signinModal.phone')}
                  placeholder='(123) 456-7890'
                  country={'ca'}
                  onlyCountries={['ca', 'us']}
                  masks={{ ca: '(...) ...-....', us: '(...) ...-....' }}
                  value={values.phone_number}
                  onChange={(phone) => {
                    setFieldValue('phone_number', phone);
                    setEnteredPhoneNumber(phone);
                  }}
                  onBlur={handleBlur}
                  isValid={
                    touched.phone_number && errors.phone_number ? false : true
                  }
                  inputStyle={{ width: '100%', height: '40px' }}
                  inputProps={{
                    name: 'phone_number',
                  }}
                  disableCountryCode={true}
                />
                {touched.phone_number && errors.phone_number && (
                  <FormHelperText
                    style={{ color: 'red', marginLeft: '5px' }}
                    error={
                      touched.phone_number && errors.phone_number ? true : false
                    }
                  >
                    {errors.phone_number}
                  </FormHelperText>
                )}
              </FieldWrapper>
              <SwitchWrapper>
                <div>{t('signinModal.emailMe')}</div>
                <div>
                  <IOSSwitch
                    name='marketing'
                    checked={values.marketing}
                    onChange={handleChange}
                  />
                </div>
              </SwitchWrapper>
              <TermsConditions>
                {t('signinModal.termsCondition')}
                <br />
                <TermsLink
                  href='https://smoothcommerce.tech/user-terms/terms.html'
                  target='_blank'
                >
                  {t('signinModal.terms')}
                </TermsLink>
                &nbsp; {t('signinModal.and')} &nbsp;
                <TermsLink
                  target='_blank'
                  href='https://smoothcommerce.tech/privacy.html'
                >
                  {t('signinModal.policy')}
                </TermsLink>
                &nbsp;
              </TermsConditions>

              <ButtonWrapper>
                <CustomButton
                  type='submit'
                  disabled={Object.keys(errors).length > 0 ? true : false}
                  padding='10px 50px;'
                >
                  {t('signinModal.continue')}
                </CustomButton>
              </ButtonWrapper>
            </form>
          </>
        )}
      </Formik>
    </>
  );
};

export default RegistrationForm;
