import styled from 'styled-components';
export const StyledSelect = styled.select`
  font-size: 17px;
  color: #fff;
  font-weight: 600;
  border: none;
  background: none;
  width: 90px;
  &:hover {
    color: ${(props) => props.theme.headerHoverColor};
    transition: all 0.25s ease;
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
`;

export const StyledOption = styled.option`
  font-size: 14px;
  background-color: white;
  font-size: 17px;
  color: #000;
  font-weight: bold;
  border: none;
  &:hover {
    color: #e46518;
  }
  background-color: yellow;
`;
