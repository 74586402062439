import { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledSelect, StyledOption } from './TranslationToggleStyles';
import { useSearchParams } from 'react-router-dom';

export const TranslationToggle = () => {
  let { i18n } = useTranslation();
  let [searchParams] = useSearchParams();
  const defaultLanguage = searchParams.get('lang');
  console.log('defaultLanguage', defaultLanguage);
  const updateLanguage = useCallback(
    (lng?: string) => {
      if (lng) {
        i18n.changeLanguage(lng);
        localStorage.setItem('selectedLanguage', lng);
      } else {
        if (defaultLanguage) {
          i18n.changeLanguage(defaultLanguage);
          localStorage.setItem('selectedLanguage', defaultLanguage);
        }
      }
    },
    [i18n, defaultLanguage]
  );
  useEffect(() => {
    updateLanguage();
  }, [updateLanguage]);
  return (
    <StyledSelect
      value={i18n.language}
      onChange={(e) => updateLanguage(e.target.value)}
    >
      <StyledOption value='en'>English</StyledOption>
      <StyledOption value='fr'>French</StyledOption>
    </StyledSelect>
  );
};
