import * as React from 'react';
import { Formik } from 'formik';
import { TextField, FormHelperText } from '@mui/material';
import { ForgotPaswordMethodProps } from './auth.types';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import PhoneInput from 'react-phone-input-2';
import { ButtonWrapper } from './authStyles';
import CustomButton from '../../components/CustomButton';
import { object, string } from 'yup';
import { forgotPassword } from './authActions';
import { useAppDispatch } from '../../store/reduxHooks';
import {
  ForgotPasswordContentWrapper,
  Title,
  Message,
  Icon,
  BackText,
  BackToSelection,
} from './authStyles';
import { clearError } from './authSlice';
import { useTranslation } from 'react-i18next';
import appConfig from '../../skins/baseIndex.json';
const ForgotPasswordMethod: React.FC<ForgotPaswordMethodProps> = ({
  setEnteredPhoneNumber,
  setEnteredEmail,
  step,
  setStep,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const initialValue =
    step?.method === 'email'
      ? { email: '', source: appConfig.configs.appSource }
      : { phone_number: '', source: appConfig.configs.appSource };
  const validationSchemaEmail = object().shape({
    email: string()
      .matches(
        new RegExp(
          '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@' +
            '[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'
        ),
        t('fieldValidation.validEmailFormat')
      )
      .required(t('fieldValidation.required')),
  });
  const validationSchemaSMS = object().shape({
    phone_number: string()
      .required(t('fieldValidation.required'))
      .min(10, t('fieldValidation.validPhoneNumberFormat')),
  });
  const validationSchema =
    step?.method === 'email' ? validationSchemaEmail : validationSchemaSMS;
  return (
    <ForgotPasswordContentWrapper>
      <BackToSelection
        onClick={() => {
          setStep({ id: 0, method: 'email' });
          dispatch(clearError());
        }}
      >
        <Icon icon={faChevronLeft} fontSize='15px'></Icon>
        <BackText>{t('signinModal.back')}</BackText>
      </BackToSelection>
      <Title align='left'>{t('signinModal.forgotPassword')}</Title>
      <Message>
        {t('signinModal.forgotPasswordDetailMessage', {
          verificationMethod:
            step?.method === 'email'
              ? t('signinModal.email')
              : t('signinModal.phone'),
        })}
      </Message>
      <Formik
        initialValues={initialValue}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          let payload;
          if (step?.method === 'email') {
            payload = {
              email: values.email,
              source: appConfig.configs.appSource,
            };
          } else {
            payload = {
              phone_number: values.phone_number,
              source: appConfig.configs.appSource,
            };
          }
          dispatch(forgotPassword({ payload, setStep, step }));
        }}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <>
              {step?.method === 'email' ? (
                <>
                  <TextField
                    id='RegistrationForm-Email-Field'
                    fullWidth
                    label={t('signinModal.email')}
                    name='email'
                    type='email'
                    size='small'
                    autoComplete='username'
                    value={values.email}
                    onChange={(e) => {
                      setFieldValue('email', e.target.value);
                      setEnteredEmail(e.target.value);
                      setEnteredPhoneNumber('');
                    }}
                    onBlur={handleBlur}
                    error={touched.email && errors.email ? true : false}
                    sx={{
                      backgroundColor: '#fff',
                    }}
                    autoFocus
                  />
                  {touched.email && errors.email && (
                    <FormHelperText
                      style={{ color: 'red', marginLeft: '5px' }}
                      error={touched.email && errors.email ? true : false}
                    >
                      {errors.email}
                    </FormHelperText>
                  )}
                </>
              ) : (
                <>
                  <PhoneInput
                    specialLabel={t('signinModal.phone')}
                    placeholder='(123) 456-7890'
                    country={'ca'}
                    onlyCountries={['ca', 'us']}
                    masks={{ ca: '(...) ...-....', us: '(...) ...-....' }}
                    value={values.phone_number}
                    onChange={(phone) => {
                      setFieldValue('phone_number', phone);
                      setEnteredPhoneNumber(phone);
                      setEnteredEmail('');
                    }}
                    onBlur={handleBlur}
                    isValid={
                      touched.phone_number && errors.phone_number ? false : true
                    }
                    inputStyle={{ width: '100%', height: '40px' }}
                    inputProps={{
                      name: 'phone_number',
                    }}
                    disableCountryCode={true}
                  />
                  {touched.phone_number && errors.phone_number && (
                    <FormHelperText
                      style={{ color: 'red', marginLeft: '5px' }}
                      error={
                        touched.phone_number && errors.phone_number
                          ? true
                          : false
                      }
                    >
                      {errors.phone_number}
                    </FormHelperText>
                  )}
                </>
              )}
              <ButtonWrapper margin='10px 0 0 0'>
                <CustomButton
                  type='submit'
                  disabled={Object.keys(errors).length > 0 || !dirty}
                  padding='10px 50px'
                >
                  {t('signinModal.continue')}
                </CustomButton>
              </ButtonWrapper>
            </>
          </form>
        )}
      </Formik>
    </ForgotPasswordContentWrapper>
  );
};

export default ForgotPasswordMethod;
