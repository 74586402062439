import { useState } from 'react';
import styled from 'styled-components';
import { TextField, InputAdornment, FormHelperText } from '@mui/material';
import { PasswordFieldProps } from '../modules/auth/auth.types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faEyeSlash,
  faCircleXmark,
  faCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import appConfig from '../skins/baseIndex.json';
type StyleProps = {
  active?: boolean;
};
const CheckText = styled.p<StyleProps>`
  width: 100%;
  margin: 0 0 0 4px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => (props.active ? '#000' : '#66696e')};
`;
const CheckWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;
const CustomIcon = styled(FontAwesomeIcon)<StyleProps>`
  font-size: 16px;
  color: ${(props) => (props.active ? 'green' : '#66696e')};
  cursor: pointer;
`;
const PasswordComplexityWrapper = styled.div`
  margin: 10px 0 25px 0;
`;
export default function PasswordField({
  oneLowerCheck,
  oneUpperCheck,
  oneSymbolCheck,
  minLengthCheck,
  minPasswordLength,
  oneNumberCheck,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  ...rest
}: PasswordFieldProps) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  return (
    <>
      <TextField
        {...rest}
        id='RegistrationForm-PasswordField'
        fullWidth
        label={t('signinModal.password')}
        name='password'
        type={showPassword ? 'text' : 'password'}
        size='small'
        autoComplete='off'
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <CustomIcon
                icon={showPassword ? faEye : faEyeSlash}
                onClick={() => setShowPassword(!showPassword)}
              />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          required: false,
        }}
        error={touched?.password && errors.password ? true : false}
        // error={errors?.password}
        sx={{
          backgroundColor: '#fff',
        }}
      />
      {touched?.password && errors.password && (
        <FormHelperText style={{ color: 'red', marginLeft: '5px' }}>
          {errors.password}
        </FormHelperText>
      )}
      <PasswordComplexityWrapper>
        <CheckWrapper>
          {minLengthCheck ? (
            <CustomIcon icon={faCircleCheck} active={minLengthCheck} />
          ) : (
            <CustomIcon icon={faCircleXmark} />
          )}
          <CheckText active={minLengthCheck}>
            {t('general.passwordMinLength', {
              minPasswordLength: minPasswordLength,
            })}
          </CheckText>
        </CheckWrapper>
        <CheckWrapper>
          {oneNumberCheck ? (
            <CustomIcon icon={faCircleCheck} active={oneNumberCheck} />
          ) : (
            <CustomIcon icon={faCircleXmark} />
          )}
          <CheckText active={oneNumberCheck}>
            {t('general.oneNumber')}
          </CheckText>
        </CheckWrapper>
        <CheckWrapper>
          {oneSymbolCheck ? (
            <CustomIcon icon={faCircleCheck} active={oneSymbolCheck} />
          ) : (
            <CustomIcon icon={faCircleXmark} />
          )}
          <CheckText active={oneSymbolCheck}>
            {t('general.oneSymbol')}
          </CheckText>
        </CheckWrapper>
        <CheckWrapper>
          {oneUpperCheck && oneLowerCheck ? (
            <CustomIcon
              icon={faCircleCheck}
              active={oneUpperCheck && oneLowerCheck}
            />
          ) : (
            <CustomIcon icon={faCircleXmark} />
          )}
          <CheckText active={oneUpperCheck && oneLowerCheck}>
            {t('general.oneUppercaseLowercase')}
          </CheckText>
        </CheckWrapper>
      </PasswordComplexityWrapper>
    </>
  );
}
